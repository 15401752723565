import React from 'react'
import { Icon, IconTypeEnum } from '@/components/common/icon';
import { FormTypeEnum } from '../../modals/types';
import { setAuthParamToURL } from '../../modals/utils';
import useOnboardingForClient from '@/hooks/useOnboardingForClient';
import { marketingOnboardingAnswers } from '@/marketing/utils';

const OnboardingBanner = () => {
  const { isActiveOnboarding } = useOnboardingForClient()

  return (
    <div>
      {isActiveOnboarding &&
        <div className="header__onboarding-banner" onClick={() => {
          setAuthParamToURL(location, FormTypeEnum.Onboarding)
          marketingOnboardingAnswers("step-0", "The onboarding modal was opened")
        }}>
          <Icon type={IconTypeEnum.Time} />
          <p className='header__text-free-call'>Получите вашу <span>1-ю бесплатную консультацию</span></p>
        </div>}
    </div>
  )
}
export default OnboardingBanner

