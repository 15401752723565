// todo: divide FormTypeEnum for login, common and email confirmation enums
export enum FormTypeEnum {
  Login = "login",
  LoginBeforeCall = "login-before-call",
  LoginOnAuthorizedOnlyPage = "login-on-authorized-only-page",
  LoginByEmail = "login_by_email",
  LoginAsExpert = "login_as_expert",
  CodeFromSMS = "code_from_sms",
  CodeFromMail = "code_from_mail",
  Questionnaire = "questionnaire",
  BirthDateQuestionnaire = "birth_date_questionnaire",
  PhoneQuestionnaire = "phone_questionnaire",
  Support = "support",
  Welcome = "welcome",
  Troubles = "troubles",
  EmailConfirmationSuccess = "email_confirmation_success",
  EmailConfirmationFailed = "email_confirmation_failed",
  StatusCodeReCaptchaError = "status_code_recaptcha_error",
  ReferralKeyError = "referral_key_error",
  Onboarding = "onboarding",
  CashBackInformation = "cashback_information"
}

export enum LoginTypeEnum {
  LoginByPhone = "LoginByPhone",
  LoginByEmail = "LoginByEmail",
}

export enum ErrorCodeEnum {
  StatusCodeInternalError = 1,
  StatusCodeAccessDenied = 3,
  StatusCodeBadRequest = 5,
  StatusCodeNoSuchAccount = 21,
  StatusCodeWrongCredentials = 22,
  StatusCodeAuthMethodNotSupported = 23,
  StatusCodeInvalidPhone = 24,
  StatusCodeEmailNotVerified = 25,
  StatusCodeTooManyRequests = 26,
  StatusCodeEmailAlreadyUsed = 27,
  StatusCodePhoneNotSupported = 31,
  StatusCodeBlockedUsers = 32,
  StatusCodeReCaptchaError = 45,
  StatusCodePhoneAlreadyInUse = 51,
  NoAlternativeAuthorizationMethod = 70,
  errMaxCountOfUsersWithSuchIpExceeded = 80
}

export interface LoginChangeForm {
  setPrevValueCallback: (value: string) => void,
}

export type LoginAcceptForm = {
  contactValue: string,
}
